import { EventTypes, FormField } from "@max/common";
import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { PatternFormat } from "react-number-format";
import styled, { css } from "styled-components";
import {
  useActionContext,
  useDataContext,
  useEventConfig,
  useLocationContext,
  useUserActionTrackingContext,
} from "../Components";
import { CountryPhone } from "../melodies-source/CountryPhone";
import { countryList } from "../melodies-source/CountryPhone/countryList";
import { CountrySelect } from "../melodies-source/CountryPhone/CountrySelect";
import { Link } from "../melodies-source/Link";
import { Select } from "../melodies-source/Select";
import { Checkbox } from "../melodies-source/Selectable";
import { SvgCheckCircle } from "../melodies-source/Svgs/CheckCircle";
import { Label } from "../melodies-source/Text";
import { HTMLInput, TextInput as Input } from "../melodies-source/TextInput";
import { DateOfBirthInput } from "./DateOfBirthInput";
import { Drawer, DrawerButton } from "./Drawer";
import { PlacesAutocomplete } from "./PlacesAutocomplete";

const DefaultFields = [
  "firstName",
  "lastName",
  "email",
  "phone",
  "countryCode",
  "zip",
  "terms",
  "sms",
  "customTerms",
  "customAgreement",
] as const;
type DefaultField = (typeof DefaultFields)[number];

const DefaultCheckboxFields: DefaultField[] = [
  "terms",
  "sms",
  "customTerms",
  "customAgreement",
];

const isDefaultField = (fieldName: string): fieldName is DefaultField =>
  DefaultFields.includes(fieldName as DefaultField);

export const RegisterForm = () => {
  const { t } = useTranslation();
  const { register, type, meta } = useEventConfig();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [showValidation, setShowValidation] = useState<boolean>(false);
  const [phoneValidation, setPhoneValidation] = useState<boolean>(false);
  const [countrySelectOpen, setCountrySelectOpen] = useState(false);
  const { handleAction, showSweepstakesRules } = useActionContext();
  const { track } = useUserActionTrackingContext();
  const locationContext = useLocationContext();
  const {
    data: { values },
    submit,
    setValue,
  } = useDataContext();

  const customFields = useMemo(
    () =>
      Object.fromEntries(
        register?.customFields?.map((field) => [`custom-${field.id}`, field]) ||
          [],
      ),
    [register?.customFields],
  );

  const defaultIds = useMemo(
    () => [
      ...Object.keys(customFields),
      "terms",
      "customTerms",
      "customAgreement",
      "sms",
    ],
    [customFields],
  );

  const fieldsOrder = useMemo(() => {
    const withOrder = (register?.fieldsOrder ?? []).map((field) =>
      isDefaultField(field.id)
        ? field
        : { id: `custom-${field.id}`, index: field.index },
    );

    withOrder.sort((a, b) => a.index - b.index);

    const withoutOrder = defaultIds
      .filter((item) => !withOrder.some((o) => o.id === item))
      .map((id, index) => ({ id, index }));

    return [...withOrder, ...withoutOrder];
  }, [defaultIds, register?.fieldsOrder]);

  const handleSubmit = () => {
    if (
      DefaultFields.find((f) => !!getValidation(f)) ||
      Object.keys(customFields).find((f) => !!getValidation(f))
    ) {
      setShowValidation(true);
      return;
    }

    const defaultValuesEntries = defaultIds
      .filter((id) => {
        const customField = customFields[id];

        return (
          customField?.type === "checkbox" ||
          DefaultCheckboxFields.includes(id as DefaultField)
        );
      })
      .filter((id) => {
        const customField = customFields[id];
        return customField ? true : !!renderDefaultField(id);
      })
      .map((id) => [id, false]);

    const defaultValues = Object.fromEntries(defaultValuesEntries);

    setSubmitting(true);

    submit(defaultValues)
      .then(() => {
        track({
          event: "click",
          context: {
            type: "register",
            value: "complete",
          },
        });
        handleAction("close");
      })
      .catch((e) => {
        setSubmitting(false);
      });
  };

  const countryCode = values.countryCode || meta?.country;
  const isUSA = !(!!countryCode && countryCode !== "US");

  const showPhoneField = register?.phoneValidation !== "no-phone";

  const showCountryField =
    register?.optionalPhone ||
    register?.phoneValidation === "optional" ||
    register?.phoneValidation === "no-phone";

  const selectedCountry = useMemo(
    () => countryList.find((c) => c.value === values.countryCode),
    [values.countryCode],
  );

  useEffect(() => {
    if (
      showCountryField &&
      locationContext?.countryCode &&
      !values.countryCode
    ) {
      setValue({
        countryCode: locationContext?.countryCode,
      });
    }
  }, [
    locationContext?.countryCode,
    setValue,
    showCountryField,
    values.countryCode,
  ]);

  const getValidation = (fieldName: string | DefaultField) => {
    if (isDefaultField(fieldName)) {
      switch (fieldName) {
        case "firstName":
          if (!values.firstName) {
            return <Validation>{t("Please enter your first name")}</Validation>;
          }
          break;
        case "lastName":
          if (!values.lastName) {
            return <Validation>{t("Please enter your last name")}</Validation>;
          }
          break;
        case "email":
          if (!values.email || !/(.+)@(.+){2,}\.(.+){2,}/.test(values.email)) {
            return <Validation>{t("Please enter a valid email")}</Validation>;
          }
          break;
        case "phone":
          if (!showCountryField && !phoneValidation) {
            return <Validation>{t("Please enter your phone")}</Validation>;
          }
          break;
        case "countryCode":
          if (showCountryField && !values.countryCode) {
            return <Validation>{t("Please enter your country")}</Validation>;
          }
          break;
        case "zip":
          return isUSA ? (
            /^\d{5}(?:[-\s]\d{4})?$/.test(values.zip || "") ? null : (
              <Validation>{t("Please enter a valid zip code")}</Validation>
            )
          ) : null;
        case "terms":
          if (!values.terms) {
            return <Validation>{t("Please agree to the terms")}</Validation>;
          }
          break;
        case "sms":
          if (!showCountryField && !register?.optionalSms && !values.sms) {
            return (
              <Validation>{t("Please agree to the sms policy")}</Validation>
            );
          }
          break;
        case "customTerms":
          if (!!register?.customTerms && !values.customTerms) {
            return <Validation>{t("Please agree to the terms")}</Validation>;
          }
          break;
        case "customAgreement":
          if (!!register?.customAgreement && !values.customAgreement) {
            return <Validation>{t("Please agree to the terms")}</Validation>;
          }
          break;
        default:
          break;
      }
    } else if (fieldName.startsWith("custom-")) {
      const field = customFields[fieldName];
      if (field?.required && !values[fieldName]) {
        return <Validation>{t("This field is required")}</Validation>;
      }

      if (field.type === "date") {
        const val = DateTime.fromFormat(values[fieldName] || "", `MM/dd/yyyy`);
        if (!val.isValid) {
          return <Validation>{t("Enter a valid birthday")}</Validation>;
        }
        if (
          field?.requiredAge &&
          -1 * val.diffNow("years").years < field?.requiredAge
        ) {
          return (
            <Validation>
              {t(`You must be ${field?.requiredAge} to enter`)}
            </Validation>
          );
        }
      }
    }

    return null;
  };

  const renderCustomField = (key: string, field: FormField) => {
    if (field.type === "select") {
      return (
        <Label>
          {field.label}
          <Select
            placeholder={field.placeholder}
            value={values[key]}
            onChange={(val) => setValue({ [key]: val })}
            options={field.options}
          />
          {showValidation && getValidation(key)}
        </Label>
      );
    } else if (field.type === "text") {
      return (
        <Label>
          {field.label}
          <TextInput
            placeholder={field.placeholder}
            value={values[key]}
            onChange={(val) => setValue({ [key]: val })}
          />
          {showValidation && getValidation(key)}
        </Label>
      );
    } else if (field.type === "date") {
      return (
        <Label>
          {field.label}
          {field.variant === "dob" ? (
            <DateOfBirthInput
              value={values[key]}
              onChange={(val) => setValue({ [key]: val })}
            />
          ) : (
            <PatternFormat
              format="##/##/####"
              mask={["M", "M", "D", "D", "Y", "Y", "Y", "Y"]}
              customInput={CustomInput}
              placeholder={field.placeholder}
              value={values[key]}
              onChange={(e) => setValue({ [key]: e.target.value })}
            />
          )}
          {showValidation && getValidation(key)}
        </Label>
      );
    } else if (field.type === "checkbox") {
      return (
        <Label>
          <StyledCheckbox
            value={!!values[key]}
            onChange={() => setValue({ [key]: !values[key] })}
            label={
              <BasicTextFormatting
                dangerouslySetInnerHTML={{
                  __html: field.label || "",
                }}
              />
            }
          />
          {showValidation && getValidation(key)}
        </Label>
      );
    } else if (field.type === "image-select") {
      return (
        <Label>
          {field.label}
          <ImageGrid>
            {field.options.map((option, index) => {
              const vals: string[] = values[key] ?? [];
              const checked = vals.includes(option.value);

              return (
                <ImageGridItem
                  key={index}
                  $checked={checked}
                  onClick={() =>
                    setValue({
                      [key]: checked
                        ? vals.filter((val) => val !== option.value)
                        : [...vals, option.value],
                    })
                  }
                >
                  {checked && (
                    <ImageGridOverlay>
                      <SvgCheckCircle color="black" width={42} height={42} />
                    </ImageGridOverlay>
                  )}
                  <ImageGridImg src={option.image} alt={option.label} />
                </ImageGridItem>
              );
            })}
          </ImageGrid>
          {showValidation && getValidation(key)}
        </Label>
      );
    } else if (field.type === "google-maps-autocomplete") {
      return (
        <div style={{ marginBottom: 20 }}>
          <Label style={{ marginBottom: 10 }}>{field.label}</Label>
          <PlacesAutocomplete
            placeholder="Type a venue or address..."
            initialQuery={values[key]?.address}
            onChange={(result, option) => {
              if ("place_id" in result) {
                setValue({
                  [key]: {
                    address: option.label,
                    placeId: result.place_id,
                    lat: result.geometry.location.lat,
                    lng: result.geometry.location.lng,
                  },
                });
              } else {
                setValue({
                  [key]: {
                    address: option.label,
                    placeId: result.placeId,
                    lat: result.lat,
                    lng: result.lng,
                  },
                });
              }
            }}
          />
          {showValidation && getValidation(key)}
        </div>
      );
    }

    return null;
  };

  const renderDefaultField = (id: string) => {
    if (id === "terms") {
      return (
        <Label>
          <StyledCheckbox
            value={!!values.terms}
            onChange={() => setValue({ terms: !values.terms })}
            label={
              <BasicTextFormatting
                dangerouslySetInnerHTML={{
                  __html: register?.terms || "",
                }}
              />
            }
          />
          {showValidation && getValidation("terms")}
        </Label>
      );
    } else if (id === "customTerms" && register?.customTerms) {
      return (
        <Label>
          <StyledCheckbox
            value={!!values.customTerms}
            onChange={() => setValue({ customTerms: !values.customTerms })}
            label={
              <BasicTextFormatting
                dangerouslySetInnerHTML={{
                  __html: register?.customTerms || "",
                }}
              />
            }
          />
          {showValidation && getValidation("customTerms")}
        </Label>
      );
    } else if (id === "customAgreement" && register?.customAgreement) {
      return (
        <Label>
          <StyledCheckbox
            value={!!values.customAgreement}
            onChange={() =>
              setValue({ customAgreement: !values.customAgreement })
            }
            label={
              <BasicTextFormatting
                dangerouslySetInnerHTML={{
                  __html: register?.customAgreement || "",
                }}
              />
            }
          />
          {showValidation && getValidation("customAgreement")}
        </Label>
      );
    } else if (id === "sms" && showPhoneField) {
      return (
        <Label>
          <StyledCheckbox
            value={!!values.sms}
            onChange={() => setValue({ sms: !values.sms })}
            label={
              <BasicTextFormatting
                dangerouslySetInnerHTML={{
                  __html:
                    register?.sms ||
                    "I'd like to receive text messages from The Artist or on behalf of them. Consent is not a condition of purchase. Message data rates may apply.",
                }}
              />
            }
          />
          {showValidation && getValidation("sms")}
        </Label>
      );
    }

    return null;
  };

  return (
    <Drawer title={register?.title} subtitle={register?.subtitle}>
      <form>
        <NameContainer>
          <Label style={{ flexGrow: 1 }}>
            {t("First Name")}
            <FirstNameInput
              type="text"
              placeholder={t("First Name") as string}
              value={values.firstName || ""}
              onChange={(val) => setValue({ firstName: val })}
            />
            {showValidation && getValidation("firstName")}
          </Label>
          <Label style={{ flexGrow: 1 }}>
            {t("Last Name")}
            <LastNameInput
              type="text"
              placeholder={t("Last Name") as string}
              value={values.lastName || ""}
              onChange={(val) => setValue({ lastName: val })}
            />
            {showValidation && getValidation("lastName")}
          </Label>
        </NameContainer>
        <Label>
          {t("Email")}
          <TextInput
            type="email"
            placeholder={t("your@email.com") as string}
            value={values.email || ""}
            onChange={(val) => setValue({ email: val })}
          />
          {showValidation && getValidation("email")}
        </Label>
        {showCountryField && (
          <Label>
            {t("Country")}
            <CountrySelect
              selected={selectedCountry}
              onChange={(country) => setValue({ countryCode: country.value })}
              options={countryList}
              onOpen={setCountrySelectOpen}
              isOpen={countrySelectOpen}
              standalone={true}
              placeholder="Select country"
            />
            {showValidation && getValidation("countryCode")}
          </Label>
        )}
        {showPhoneField && (
          <Label>
            {t("Phone Number")} {showCountryField && `(${t("optional")})`}
            <CountryPhone
              defaultCountryCode={meta?.country || undefined}
              value={values.phone || ""}
              hideCountrySelect={showCountryField}
              onChange={({ value, countryCode, isValid }) => {
                setValue(
                  showCountryField
                    ? { phone: value }
                    : { phone: value, countryCode },
                );
                setPhoneValidation(!!isValid);
              }}
            />
            {showValidation && getValidation("phone")}
          </Label>
        )}
        <Label>
          {!isUSA ? t("Postal Code") : t("Zip Code")}
          <TextInput
            type="text"
            placeholder={
              (!isUSA ? t("Postal code") : t("5 digit code")) as string
            }
            value={values.zip || ""}
            onChange={(val) => setValue({ zip: val })}
          />
          {showValidation && getValidation("zip")}
        </Label>
        {fieldsOrder.map((fieldOrder) => {
          const customField = customFields[fieldOrder.id];

          return customField
            ? renderCustomField(fieldOrder.id, customField)
            : renderDefaultField(fieldOrder.id);
        })}
      </form>
      <DrawerButton
        onClick={handleSubmit}
        isDisabled={submitting}
        isSecondaryTextColor={type === EventTypes.Round}
        aria-label={register?.ctalabel || t("Check In") || "Submit"}
      >
        {submitting ? t("Submitting") : register?.ctalabel || t("Check In")}
      </DrawerButton>
      {register?.disclaimers && (
        <Disclaimers>
          {register?.disclaimers.map((disclaimer, i) => (
            <BasicTextFormatting
              key={i}
              dangerouslySetInnerHTML={{
                __html: disclaimer,
              }}
            />
          ))}
        </Disclaimers>
      )}
      {register?.hasSweepstakes && (
        <Sweeps>
          {t("No purchase necessary. Void where prohibited. By clicking")}{" "}
          {register?.ctalabel || t("Check In")}{" "}
          {t("you acknowledge and accept the")}{" "}
          <Link tabIndex={0} onClick={() => showSweepstakesRules()}>
            {t("Official Rules")}
          </Link>
        </Sweeps>
      )}
    </Drawer>
  );
};

export const basicTextFormattingStyles = css`
  b {
    font-weight: 600;
  }
  strong {
    font-weight: 700;
  }
  i {
    font-style: italic;
  }
  small {
    font-size: 60%;
  }
`;

export const BasicTextFormatting = styled.div`
  ${basicTextFormattingStyles}
  a {
    color: #01207e;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  align-items: baseline;
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Validation = styled.div`
  color: #e45c52;
  margin: 10px 0;
`;

const TextInput = styled(Input)`
  box-shadow: none;
  margin-top: 5px;
  input {
    box-shadow: none;
  }
  input:focus {
    box-shadow: none;
  }
`;

const FirstNameInput = styled(TextInput)`
  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 1px solid rgba(0, 0, 0, 0);
  }
`;

const LastNameInput = styled(TextInput)`
  input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

const Disclaimers = styled.div`
  font-size: 12px;
  padding: 20px 10px 10px 10px;
  div {
    padding-top: 10px;
  }
`;

const Sweeps = styled.p`
  font-size: 12px;
  padding: 20px 10px 10px 10px;
  ${Link} {
    font-size: 12px;
    display: inline;
  }
`;

const CustomInput = styled(HTMLInput)`
  box-shadow: none;
  &:focus {
    box-shadow: none;
  }
`;

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-auto-rows: auto;
  gap: 20px;
  margin-top: 10px;
`;

const ImageGridItem = styled.div<{ $checked?: boolean }>`
  aspect-ratio: 1;
  position: relative;
`;

const ImageGridOverlay = styled.div`
  position: absolute;
  inset: 0;
  background: rgba(255, 255, 255, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
`;

const ImageGridImg = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: contain;
`;
