import React from "react";
import styled, { css } from "styled-components";
import { Body1, Body2 } from "../Text";
import { CheckboxInput } from "./CheckboxInput";
import { RadioInput } from "./RadioInput";

const variants = ["radio", "checkbox"] as const;
type Variant = (typeof variants)[number];

const HoverIndicator = styled.div`
  background: ${(p) => p.theme.colors.black20}33;
  height: 32px;
  width: 32px;
  border-radius: 100%;
  display: none;
  position: absolute;
  top: -2.5px;
  left: -9px;
  z-index: -1;
`;

export type SelectableProps = {
  label?: string | JSX.Element;
  value: boolean;
  description?: string;
  variant?: Variant;
  onChange?: (v: boolean) => void;
} & React.HTMLAttributes<HTMLLabelElement>;

export const Selectable = ({
  label,
  value,
  onChange,
  variant = "radio",
  description,
  ...props
}: SelectableProps) => {
  const toggle = () => onChange?.(!value);

  const textUI = (
    <Label onClick={() => toggle()}>
      <Body1>{label}</Body1>
      {description && <Body2>{description}</Body2>}
    </Label>
  );

  return (
    <Container
      {...props}
      style={{ zIndex: 1 }}
      aria-checked={value}
      tabIndex={0}
      onKeyDown={({ code }) => {
        if (code === "Space" || code === "Enter") toggle();
      }}
      onClick={() => toggle()}
    >
      {variant === "radio" && <RadioInput isSelected={value} />}
      {variant === "checkbox" && <CheckboxInput isSelected={value} />}
      <HoverIndicator />
      {label && textUI}
    </Container>
  );
};

const Container = styled.label`
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  outline-color: var(--template-primary);
  :hover {
    cursor: pointer;
    ${HoverIndicator} {
      display: block;
    }
  }
`;

const Label = styled.div`
  width: 100%;
  cursor: pointer;
  margin: 5px 0px 5px 10px;
`;

type RadioInputWrapperProps = {
  isSelected: boolean;
};
export const RadioInputWrapper = styled.div<RadioInputWrapperProps>`
  margin-top: 18px;
  display: flex;
  ${(props) =>
    props.isSelected &&
    css`
      font-weight: 500;
    `}
  :hover {
    cursor: pointer;
    opacity: 0.8;
  }
  ${(props) => props.theme.mediaQueries.mobile} {
    border: solid 1px ${(props) => props.theme.colors.navy};
    border-radius: 4px;
    padding: 10px 13px;
    display: flex;
    :hover {
      cursor: pointer;
      opacity: 0.8;
      border: solid 1px ${(props) => props.theme.colors.navy};
    }
  }
`;

export const Checkbox = ({ ...props }: SelectableProps) => (
  <Selectable variant="checkbox" {...props} role="checkbox" />
);

export const Radio = ({ ...props }: SelectableProps) => (
  <Selectable variant="radio" {...props} role="radio" />
);
