import { useEffect } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";

import { NavHeader } from ".";
import { H4 } from "../melodies-source/Text";
import { Link } from "../melodies-source/Link";
import { Helmet } from "react-helmet-async";

export const Privacy = ({
  onClose,
  setModal,
}: {
  onClose?: VoidFunction;
  setModal?: (v: string) => void;
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!onClose) {
    const goBackPath = location?.state?.back;
    onClose = goBackPath ? () => navigate(-1) : () => navigate("/");
  }

  return (
    <>
      <Helmet>
        <title>SET Privacy Policy</title>
      </Helmet>
      <Wrapper>
        <NavHeader onClose={onClose} />
        <Content>
          <H4>SET Privacy Policy</H4>
          <p>
            Music Audience Exchange, Inc., and our subsidiaries and brands
            (collectively, “<strong>SET</strong>”, ”<strong>SET.Live</strong>”,
            “<strong>MAX</strong>,” “<strong>we</strong>,” “<strong>us</strong>
            ,” and “<strong>our</strong>”) value your privacy. In this Privacy
            Policy, we describe how we collect, use, and process the personal
            information we have obtained about people who use our websites or
            who otherwise interact with us (collectively, the “Services”). Our
            Privacy Policy also describes the choices you can make about how we
            collect and use your personal information.
          </p>
          <ul>
            <li>
              California residents, please{" "}
              <a href="#users-in-california">click here</a> for our Notice of
              Collection under the California Consumer Privacy Act.
            </li>
            <li>
              European Economic Area and United Kingdom resident, please{" "}
              <a href="#eu">click here</a> to learn more about your privacy
              rights.
            </li>
          </ul>

          <p id="personal-information-we-collect">
            <strong>PERSONAL INFORMATION WE COLLECT</strong>
          </p>
          <p>
            In this Privacy Policy, “personal information” means any information
            that MAX processes that identifies, relates to, describes, is
            reasonably capable of being associated with, or could reasonably be
            linked with a particular person or household.
          </p>
          <p>
            We collect personal information when you interact with us or use our
            Services. Personal information is any piece of information that can
            identify you. Some personal information may identify you directly,
            such as your name or email address. The exact type of personal
            information we collect will depend on how you interact with us. For
            instance, we collect payment information when you use some of our
            Services.
          </p>
          <p>
            <strong>Personal Information Collected Directly from You</strong>
          </p>
          <p>
            To use some features of our Services (for example, when you send a
            tip to an artist), you must register with us or otherwise provide us
            with your personal information. We also may collect personal
            information that you enter through our Services or provide to us in
            other ways, such as through text message or email. The type of
            information we collect form you will depend on your interactions
            with us and how you use our Services, but may include:
          </p>
          <ul>
            <li>
              Personal details, such as full name, mailing address, email
              address, phone number, social network websites, and other contact
              information;
            </li>
            <li>
              Business contact information, such as your company name, your
              role, and your business email address;
            </li>
            <li>
              Purchase transaction information, such as information about the
              products you buy, billing address, method of payment, and payment
              details; and
            </li>
            <li>Other personal information you choose to provide to us.</li>
          </ul>
          <p>
            We collect personal information directly from you any time you
            choose to give us your personal information. For example, we collect
            personal information directly from you when you:
          </p>
          <ul>
            <li>Create a new account;</li>
            <li>Make a purchase using our Services;</li>
            <li>Share a photo with us;</li>
            <li>Participate in one of our contests or promotions; or</li>
            <li>Contact us by email or through our customer service number.</li>
          </ul>
          <p>
            <strong>Personal Information We Collect Automatically</strong>
          </p>
          <p>
            We, and the vendors we work with, may also collect some personal
            information automatically based on your interaction with us, and our
            websites using automated tools. For example, cookies, pixels, and
            other passive information collection technologies enable us to
            compile aggregate statistics concerning use of our Services, analyze
            trends, enhance the security of our Services, deliver content and
            advertisements, and otherwise administer and improve our Services.
            For example, we collect personal information about you automatically
            when you:
          </p>
          <ul>
            <li>
              Make a purchase, including information about the transaction, such
              as product details, purchase price, and date and location of the
              transaction;
            </li>
            <li>Complete a form on our website;</li>
            <li>
              Access and use our Services, including information about the type
              of browser used, access times, pages viewed, and IP address; or
            </li>
            <li>
              Use a computer or mobile device to access our Services, including
              information about the hardware model, operating system and
              version, unique device identifiers, and mobile network
              information.
            </li>
          </ul>
          <p>
            We use third-party web analytics services to help us understand and
            analyze how visitors use our Services. We have, for example,
            implemented Google Analytics Advertising features such as dynamic
            remarketing, interest-based advertising, audience targeting,
            behavioral reporting, demographics and interests reporting, user
            segment analysis, device reporting, display advertising, and video
            ads reporting. For more information on how Google Analytics uses
            data, visit{" "}
            <a
              href="http://www.google.com/policies/privacy/partners/"
              target="_blank"
              rel="noreferrer"
            >
              google.com/policies/privacy/partners/
            </a>
            . To opt out of Google Analytics, visit{" "}
            <a
              href="https://tools.google.com/dlpage/gaoptout"
              target="_blank"
              rel="noreferrer"
            >
              tools.google.com/dlpage/gaoptout
            </a>
            . To adjust your Google advertising settings, visit:{" "}
            <a
              href="https://myadcenter.google.com/"
              target="_blank"
              rel="noreferrer"
            >
              myadcenter.google.com
            </a>
            .
          </p>
          <p>
            Your web browser may have settings that allow you to transmit a “Do
            Not Track” signal when you visit various websites or use online
            services. Like many websites, our websites are not designed to
            respond to “Do Not Track Signals.” If you are in California, you can
            view our “Users in California” section to learn more about our
            recognition of other signals. You may be able to use your web
            browser settings or browser extensions to reject cookies and other
            tracking technologies or to notify you when a website tries to use
            these, giving you the opportunity to reject them. Please note that
            blocking cookies on our Services may affect the availability and
            functionality of the Services and other websites. Additionally,
            disabling cookies may invalidate opt outs that use cookies to
            recognize devices that have opted out.
          </p>
          <p>
            <strong>Information We Collect About Your Location</strong>
          </p>
          <p>
            Our Services may access and collect your geolocation information to
            facilitate provisions of Services, such as to provide you with
            information about shows or artists playing near you. We also may use
            information about the location of the device you are using to help
            us understand how Services are being used and to deliver more
            relevant advertising. For most mobile devices and computer systems,
            you are able to adjust your settings to limit our collection of this
            information.
          </p>
          <p>
            <strong>Personal Information from Other Parties</strong>
          </p>
          <p>
            We may get personal information about you from other sources,
            including our mailing list providers; payment processors; data
            enhancement and data analytics vendors; marketing and advertising
            vendors and partnerships; businesses we partner with for promotions
            and contests; and information you make publicly available elsewhere,
            such as through social media.
          </p>
          <p id="use-personal">
            <strong>HOW WE USE PERSONAL INFORMATION</strong>
          </p>
          <p>We may use your personal information to:</p>
          <ul>
            <li>process your orders or purchases;</li>
            <li>
              communicate with you, including to respond to your questions and
              requests, provide you with documentation or communications you
              have requested, send you notices about our Services, offer
              customer support, or contact you for information or after-sales
              support;
            </li>
            <li>
              personalize our content, including to make recommendations to you
              about our Services, to tailor the information we send or show you,
              and to offer location-based customizations and personalization
              based on your interactions with our Services;
            </li>
            <li>
              market and advertise our products, including to send you news,
              updates, special offers and promotions, and targeted advertising;
            </li>
            <li>
              monitor and analyze trends, usage, and the activities of users of
              our Services to better understand how our users access and use our
              Services in order to improve them, respond to user preferences,
              and for other research purposes;
            </li>
            <li>
              improve our Services and notify you about important updates;
            </li>
            <li>
              perform business analyses or for other business purposes, such as
              evaluating transactions involving our Services;
            </li>
            <li>
              identify, prevent, investigate, and take other actions with
              respect to suspected or actual fraud or illegal activity or other
              activity that violates our policies;
            </li>
            <li>
              ensure the security and integrity of our personal information
              processing;
            </li>
            <li>
              protect our rights, property, or safety and that of our users and
              our confidential and proprietary information;
            </li>
            <li>
              comply with applicable laws, rules, regulations, and legal
              processes as well as our company policies, including to respond to
              claims asserted against us and to enforce or administer terms and
              agreements; and
            </li>
            <li>for any other purposes, with your consent.</li>
          </ul>
          <p>
            <strong>DISCLOSURE OF YOUR INFORMATION</strong>
          </p>
          <p>
            We may disclose your personal information with our affiliates,
            business partners, and vendors. For example, we may use vendors to
            help us operationally or to administer activities on our behalf,
            such as payment processing. We also may disclose your personal
            information to: (1) as permitted or required by federal or state
            laws and regulations; (2) in the event we sell or transfer all or a
            portion of our business assets (e.g., further to a merger,
            liquidation, reorganization, or any other business transaction,
            including negotiations of such transaction); (3) when we determine
            that disclosure is necessary or appropriate to respond to legal
            process, including to disclose personal information to a court,
            legal authority, opposing party in litigation, our legal counsel, or
            other advisors in connection with a judicial proceeding, court
            order, subpoena, or other legal process; (4) to enforce our policies
            or to protect legal rights, property, or safety; or (5) with your
            consent or at your direction.
          </p>
          <p>
            Any information, including personal information, that you post on
            any public areas of our Services, such as reviews, comments, and
            photos, may be available to, and searchable by, all other users.
            Reviews also may be publicly accessible.
          </p>
          <p id="retention">
            <strong>OUR RETENTION OF YOUR PERSONAL INFORMATION</strong>
          </p>
          <p>
            We keep the personal information described above for as long as
            necessary or permitted for the purposes described in this Privacy
            Policy or otherwise authorized by law. This generally means holding
            the information for as long as one of the following apply:
          </p>
          <ul>
            <li>
              Your personal information is reasonably necessary to manage our
              operations, to manage your relationship with us, or to satisfy
              another purpose for which we collected the information;
            </li>
            <li>
              Your personal information is reasonably necessary to carry out a
              disclosed purpose that is reasonably compatible with the context
              in which the personal information was collected;
            </li>
            <li>
              The personal information is reasonably necessary to protect or
              defend our rights or property; or
            </li>
            <li>
              We are otherwise required or permitted to keep your personal
              information by applicable laws or regulations.
            </li>
          </ul>
          <p>
            Where personal information is used for more than one purpose, we
            will retain it until the purpose with the latest period expires. For
            more information about our retention practices, please contact us
            using the contact details below.
          </p>
          <p>
            <strong>USER GENERATED CONTENT</strong>
          </p>
          <p>
            Any content you post to our Services or public social media pages (“
            <strong>User Generated Content</strong>”) may be visible to and
            searchable by all users or the general public, and we may not be
            able to prevent such content from being used in a manner that
            violates this Privacy Policy, the law, or your personal privacy. For
            instance, other parties may republish your User Generated Content,
            or it may have been archived by search engines and others that we
            cannot control. We may revise such User Generated Content, remove
            it, or combine your User Generated Content with other information we
            have collected about you and use it as described in this Privacy
            Policy. All User Generated Content is subject to our Terms and
            Conditions.
          </p>
          <p>
            In addition, we may collect information from you when you contact us
            or when you submit a review, comment, or other content to our
            Services or on our social media pages. We may choose to disclose the
            personal testimonials of our customers, including your name, on our
            Services or with our affiliates, our business partners and
            franchisees, and our vendors. If you wish to update or delete your
            testimonial, you can contact us at the contact details below.
          </p>
          <p>
            <strong>YOUR CHOICES</strong>
          </p>
          <p>
            <strong>Updating Your Information</strong>
          </p>
          <p>
            If you wish to correct any of the personal information you have
            provided to us, you may do so by logging in to your account. Please
            note that outdated copies of information that you have updated may
            remain viewable in cached and archived pages for a period of time,
            and we may maintain records in our systems of this information as
            well.
          </p>
          <p>
            <strong>Communication Preferences and Marketing</strong>
          </p>
          <p>
            We may send periodic promotional or informational communications to
            you. If you do not wish to receive marketing emails from us, you can
            opt out of future emails by following the instructions provided in
            our emails. Opting out of marketing emails will not affect our
            administrative emails about your account or any product you have
            requested or received for us.
          </p>
          <p>
            You also have the choice to opt-in to receiving text messages and
            alerts on the mobile phone number(s) you disclose to us. Once you
            opt-in, we may send you text messages (1) regarding your account;
            (2) about our products and promotions (including advertisements,
            sales, and special offers); and (3) to investigate or prevent fraud.
            You do not have to opt-in to text messages and alerts to use our
            Services. If you opt-in, standard text messaging charges may apply.
            You may choose to opt-out of our text messages and alerts at any
            time by sending us a text message from your mobile phone.
          </p>
          <p id="users-in-california">
            <strong>USERS IN CALIFORNIA</strong>
          </p>
          <p>
            As a supplement to the information provided throughout this Privacy
            Policy, we provide the following information as a notice to
            California consumers. This section provides details regarding how
            MAX may process personal information of California consumers
            pursuant to the California Consumer Privacy Act (as amended by the
            California Privacy Rights, and collectively, referred to as the “
            <strong>CCPA</strong>”). In the event of conflict of this section
            and the rest of the Privacy Policy, this section will prevail as to
            California consumers and their rights under California privacy law.
          </p>
          <p>
            Unless otherwise noted, the disclosures that describe how and why we
            collect and use your personal information also describe how and why
            we collected and used your personal information within the past
            twelve (12) months.
          </p>
          <p>
            <strong>
              Personal Information Collection, Sources, and Disclosures
            </strong>
          </p>
          <p>
            We collect the following categories of personal information about
            California consumers:
          </p>
          <ul>
            <li>Identifiers</li>
            <li>Customer Records</li>
            <li>
              Characteristics of Protected Classifications under California or
              Federal Law
            </li>
            <li>Commercial Information</li>
            <li>Internet or Other Electronic Network Activity Information</li>
            <li>Geolocation Data</li>
            <li>Professional or Employment-Related Information</li>
            <li>Inferences</li>
          </ul>
          <p>
            For examples of the specific type of personal information we may
            collect about California consumers, please see the “
            <a href="#personal-information-we-collect">
              Personal Information We Collect
            </a>
            ” section above. You can learn more about our business and
            commercial purposes for data collection the data collect in the “
            <a href="#use-personal">
              How We Collect and Use Personal Information
            </a>
            ” section above. For more information about our retention practices,
            please see the “
            <a href="#retention">Our Retention of Your Personal Information</a>”
            section above.
          </p>
          <p>
            <strong>Sources of Personal Information</strong>
          </p>
          <p>
            We may collect personal information directly from you when you
            provide us information online, or through electronic forms; our
            customer service number; automatically through our Services via
            cookies and other tracking technologies; and from our service
            providers, vendors and suppliers, or other individuals and
            businesses.
          </p>
          <p>
            <strong>Disclosures of Personal Information</strong>
          </p>
          <p>
            We may disclose the following categories of personal information to
            our service providers, other vendors (including those that
            facilitate interest-based and other advertising and marketing), and
            other parties for a business purpose.
          </p>
          <ul>
            <li>Identifiers</li>
            <li>Customer Records</li>
            <li>Commercial Information</li>
            <li>Internet or other electronic network activity information</li>
            <li>Inferences</li>
          </ul>
          <p>
            The categories of persons to which we may disclose this personal
            information include our affiliates, our business partners, service
            providers, professional advisors, and external auditors. More
            information on how we disclose personal information can be found in
            the “How We Disclose Your Information” section above.
          </p>
          <p>
            <strong>Sales and Sharing of Personal Information</strong>
          </p>
          <p>
            Although we do not sell personal information in exchange for money,
            some of the ways we disclose personal information for advertising
            purposes may be considered “sales” or “sharing” under California
            law. We may “sell” or “share” the following categories of personal
            information:
          </p>
          <ul>
            <li>Identifiers</li>
            <li>Commercial Information</li>
            <li>Internet or other electronic network activity information</li>
            <li>Inferences</li>
          </ul>
          <p>
            We may sell and share personal information to the following
            categories of third parties: advertising partners and social media
            platform. We sell and share personal information for the purpose of
            analytics, data strategy, consultation on development or improvement
            of products and services, marketing, advertising, and related
            services for the benefit of MAX and our business partners.
          </p>
          <p>
            We do not have actual knowledge that we sell or share the personal
            information of anyone under the age of 16.
          </p>
          <p>
            <strong>Your California Privacy Rights</strong>
          </p>
          <p>
            <strong>Right to Know</strong>. You have the right to know:
          </p>
          <ol>
            <li>
              The categories of personal information we have collected about
              you, including:
              <ol>
                <li>
                  The categories of sources from which the personal information
                  was collected
                </li>
                <li>
                  Our business or commercial purposes for collecting (or selling
                  or sharing—if applicable) your personal information
                </li>
                <li>
                  The categories of recipients to which we disclose personal
                  information
                </li>
                <li>
                  The categories of personal information about you that we sold,
                  and for each category identified, the categories of third
                  parties to which we sold that particular category of personal
                  information; and
                </li>
                <li>
                  The categories of personal information that we disclosed for a
                  business purpose, and for each category identified, the
                  categories of recipients to which we disclosed that particular
                  category of personal information.
                </li>
              </ol>
            </li>
            <li>
              The specific pieces of personal information we have collected
              about you.
            </li>
          </ol>
          <p>
            <strong>Right to Delete</strong>. You have the right to request that
            we delete personal information that we collected from you, subject
            to certain exceptions. In response, we will delete, and instruct any
            applicable service providers or contractors to delete, your personal
            information, unless an exception applies. To exercise this right,{" "}
            <a
              href="https://auth.set.live/opt-out"
              target="_blank"
              rel="noopener noreferrer"
            >
              go here to have your data removed.
            </a>
          </p>
          <p>
            <strong>Right to Correct</strong>. If you believe that the personal
            information we maintain about you is inaccurate, you may submit a
            request for us to correct that information. Upon receipt of a
            verifiable request to correct inaccurate personal information, we
            will use commercially reasonable efforts to correct the information
            as you direct.
          </p>
          <p>
            <strong>Right to Opt Out of Sales and Sharing</strong>. You have the
            right to direct us not to sell or share your personal information.
            To opt out of the sale or sharing of personal information through
            tracking technologies such as cookies and pixels, please visit
            “Cookie Settings” available at the bottom of each page on the
            website. If you choose to use the{" "}
            <a
              href="https://globalprivacycontrol.org/"
              target="_blank"
              rel="noreferrer"
            >
              Global Privacy Control
            </a>{" "}
            (GPC) browser signal, or any other opt-out preference signal, you
            will be opted out of online, cookie-based sales or sharing of
            personal information associated with the browser for which you have
            enabled the signal. If you use multiple browsers or devices, you
            will need to activate the signal for each one that you use.
          </p>
          <p>
            <strong>Non-Discrimination Rights</strong>. We will not discriminate
            against you, if you choose to exercise any of your CCPA rights. We
            do not use the fact that you have made a privacy rights request for
            any purpose other than facilitating a response to your request.
          </p>
          <p>
            <strong>How to Submit a Privacy Rights Request</strong>
          </p>
          <p>
            You can exercise your privacy rights by emailing us at
            privacy@max.live or calling us at 844.261.3446.
          </p>
          <p>
            Only you, or someone you authorize to act on your behalf, may make a
            request related to your personal information. If you designate an
            authorized agent to make a request on your behalf, you will still
            need to verify your identity directly with us before your request
            can be processed. An authorized agent may submit a request on your
            behalf using the webform or toll-free number listed above.
          </p>
          <p>
            Certain CCPA privacy rights requests are subject to a verification
            process. If you submit a request to know, request to delete, or
            request to correct, you will be asked to log into your account or to
            provide 2-3 pieces of personal information that we will match
            against our records to verify your identity. We will not fulfill a
            CCPA privacy rights request unless we have been provided sufficient
            information for us to reasonably verify that the requestor is the
            person about whom we collected the personal information. Please
            follow any instructions provided and promptly respond to any
            follow-up inquiries so that we may confirm your identity. If you
            request that we provide you with specific pieces of information or
            request deletion or correction of certain kinds of information, we
            may apply heightened verification standards.
          </p>
          <p>
            <strong>California's Shine the Light Law</strong>
          </p>
          <p>
            California's “Shine the Light” law allows California customer to
            request a list of third parties to whom a business has disclosed
            personal information, during the preceding calendar year for
            third-party direct marketing purposes.
          </p>
          <p>
            California residents may ask MAX to provide them with (1) a list of
            certain categories of personal information that we have disclosed to
            third parties for their direct marketing purposes during the
            immediately preceding calendar year, and (2) the identity of those
            third parties. California residents may make one such request per
            calendar year.
          </p>
          <p>
            To make this request, you may contact MAX at privacy@max.live. In
            your request, please state that you are a California resident making
            a request under California's Shine the Light Law and provide a
            current California mailing address for our response. Please allow up
            to thirty (30) days for a response. MAX reserves its right not to
            respond to such requests submitted to addresses other than those
            specified in this paragraph.
          </p>
          <p>
            <em>
              Please note that rights under the CCPA and California's Shine the
              Light law must be exercised separately.
            </em>
          </p>
          <p>
            <strong>INTERNATIONAL USERS</strong>
          </p>
          <p>
            MAX is based in the United States. If you are using our Services
            outside of the United States, please be aware that the personal
            information we obtain about you may be transferred to and processed
            in the United States or other jurisdictions outside of your own.
            Your personal information may be accessible by public authorities
            where it is processed. By using our Services and providing your
            personal information, you acknowledge that your personal information
            may be processed in jurisdictions other than your own. Please be
            aware that the data protection laws and regulations that may apply
            to your personal information transferred to the United States or
            other countries may be different from the laws in your country or
            residence.
          </p>
          <p id="eu">
            <strong>
              USERS IN THE EUROPEAN ECONOMIC AREA, THE UNITED KINGDOM, AND
              SWITZERLAND
            </strong>
          </p>
          <p>
            As a supplement to the information provided throughout this Privacy
            Policy, we provide the following information as a notice to
            residents of the European Economic Area (“<strong>EEA</strong>”) of
            their rights under the European Union's General Data Protection
            Regulation, residents of the United Kingdom (“<strong>UK</strong>”)
            of their rights under the UK's General Data Protection Regulation,
            and residents of Switzerland of their right under the Swiss Federal
            Act on Data Protection.
          </p>
          <p>
            Music Audience Exchange, Inc. is the data controller for customer
            personal information collected through our Services. You may be
            required to provide certain personal information, such as your
            contact information and payment details, in order to use our
            Services and place an order for our products.
          </p>
          <p>
            <strong>Legal Bases for Our Personal Information Processing</strong>
          </p>
          <p>
            Your personal information is processed under the following legal
            bases:
          </p>
          <ul>
            <li>
              The processing is{" "}
              <strong>necessary for us to provide you with the services</strong>
              you request or to respond to your questions.
            </li>
            <li>
              We have a <strong>legal obligation</strong> to process your
              personal information, such as compliance with applicable tax laws
              or other government regulations or compliance with a court order
              or binding law enforcement request.
            </li>
            <li>
              We have a <strong>legitimate interest</strong> in processing your
              personal information and our reasons for using your personal
              information outweigh the potential prejudice to your data
              protection rights. In particular, we have a legitimate interest in
              the following instances:
              <ul>
                <li>To maintain and improve our Services;</li>
                <li>
                  To operate and promote our Services, to advertise and provide
                  you with information and communications about our Services
                  that are tailored to, and in accordance with, your
                  preferences;
                </li>
                <li>
                  To analyze and improve the safety and security of our
                  Services, including by implementing and enhancing security
                  measures and safeguards and protecting against fraud, spam,
                  and other abuses; and
                </li>
                <li>
                  To protect our legal rights, prevent misuse of our Services,
                  perform on a contract, or comply with other legal obligations.
                </li>
              </ul>
            </li>
            <li>
              You have <strong>consented to</strong> our processing of your
              personal information. When you consent, you may change your mind
              and withdraw your consent at any time by emailing us at
              privacy@max.live.
            </li>
          </ul>
          <p>
            Your provision of personal information is required in order for us
            to provide the Services. In some instances, if you fail to provide
            such personal information, you may not be able to access and use the
            Services.
          </p>
          <p>
            <strong>Your Privacy Rights</strong>
          </p>
          <p>
            You may ask us to take the following actions with respect to your
            personal information:
          </p>
          <ul>
            <li>
              <strong>Access</strong>. You may request that we provide you with
              information about our processing of your personal information and
              access to your personal information.
            </li>
            <li>
              <strong>Correction</strong>. You may request that we update or
              correct inaccuracies in your personal information.
            </li>
            <li>
              <strong>Deletion</strong>. You may request that we delete your
              personal information; to exercise this right,{" "}
              <a
                href="https://auth.set.live/opt-out"
                target="_blank"
                rel="noopener noreferrer"
              >
                go here to have your data removed.
              </a>
            </li>
            <li>
              <strong>Portability</strong>. You may request that we transfer a
              copy of your personal information to you or a third party of your
              choice.
            </li>
            <li>
              <strong>Restrict</strong>. You may request that we stop or
              restrict our processing of your personal information.
            </li>
          </ul>
          <p>
            You may submit these requests by emailing us at privacy@max.live. We
            may require specific information from you to help us verify your
            identity prior to processing your request. Applicable law may
            require or permit us to decline your request. If we decline your
            request, we will tell you why, subject to any legal restrictions on
            disclosing this information.
          </p>
          <p>
            Submitting a Complaint. If you would like to submit a complaint
            about our use of your personal information or our response to your
            request regarding your personal information, you may contact us at
            privacy@max.live or submit a complaint directly to the data
            protection authority in your jurisdiction. If you reside in the EEA,
            you can find information about your data protection authority{" "}
            <a
              href="https://edpb.europa.eu/about-edpb/about-edpb/members_en"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
            . If you reside in the UK, you may file complaints with the
            Information Commissioner's Office{" "}
            <a
              href="https://ico.org.uk/make-a-complaint/"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
            . If you reside in Switzerland, you can contact the Federal Data
            Protection and Information Commissioner{" "}
            <a
              href="https://www.edoeb.admin.ch/edoeb/en/home/the-fdpic/contact.html"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
            .
          </p>
          <p>
            <strong>Transfers of Your Personal Information</strong>
          </p>
          <p>
            When you directly provide your personal information through our
            Services, you acknowledge that your personal information is being
            provided by you to a company based in the United States. The laws
            that apply to personal information protection in the United States
            differ from those applicable in the EEA and the UK. If it is
            necessary for us to transfer personal information out of the EEA and
            the UK, we do so by using suitable data transfer safeguards, such as
            the standard contractual clauses approved by the European
            Commission, which impose data protection obligations on parties to
            the transfer. You may obtain additional information about our data
            transfer mechanisms by contacting us at privacy@max.live.
          </p>
          <p>
            <strong>SWEEPSTAKES, CONTESTS AND PROMOTIONS</strong>
          </p>
          <p>
            We may offer sweepstakes, contests, and other promotions (a “
            <strong>Promotion</strong>”) via the Services or through one of our
            social media pages that may require registration. We may request
            additional information to participate in Promotions (such as your
            photos, personal opinions, preferences and interests). Participation
            in these Promotions is completely voluntary and you may choose
            whether or not to participate and therefore disclose information.
          </p>
          <p>
            <strong>SECURITY OF YOUR PERSONAL INFORMATION</strong>
          </p>
          <p>
            We maintain appropriate safeguards designed to protect personal
            information from loss, theft, misuse, and unauthorized access,
            disclosure, alteration, and destruction. Nevertheless, no data
            security measures can guarantee 100% security. We encourage you to
            safeguard your passwords, ID numbers, and other information you use
            to access our Services.
          </p>
          <p>
            <strong>LINKS TO OTHER ONLINE PROPERTIES</strong>
          </p>
          <p>
            Our Services may include links to other online properties that we do
            not own or operate. This Privacy Policy does not apply to those
            properties, which may have their own privacy policies that you
            should review to understand how they may collect, use, or disclose
            your personal information. We are not responsible for the content or
            privacy practices of any linked websites or online properties that
            are not under our control.
          </p>
          <p>
            <strong>SOCIAL FEATURES</strong>
          </p>
          <p>
            Certain features of our Services may permit you to interact with
            social media networks operated by unaffiliated parties, for example,
            if you “like” or “follow” us on those platforms (“
            <strong>Social Features</strong>”). If you choose to “like” or share
            content or post information using Social Features, that information
            may be publicly displayed, and the party operating the social media
            platform may receive information about you and your use of our
            Services. Similarly, if you interact with us through Social
            Features, we may have access to information about you from the
            social media platform. In addition, we may track when you like us,
            follow us, or share our content through Facebook, Twitter, or other
            social media platforms.
          </p>
          <p>
            You may also choose to use Social Features on the Services such as
            logging into the Services with your username and password from
            another website. As part of the functionality of the Services, we
            may offer you the ability to connect your social network account
            with certain applications such as Spotify. If you choose to connect
            to one of these social network account, we will disclose certain
            personal information with such social network.
          </p>
          <p>
            Please note that if you mention MAX, or comment about or in response
            to us, in your post on a social media platform, that platform may
            allow us to publish your post on our Services or public social media
            pages or otherwise use your post about us. You should review the
            terms, policies, and settings of these platforms to learn more about
            their data practices and adjust your settings accordingly.
          </p>
          <p>
            <strong>PRIVACY OF CHILDREN</strong>
          </p>
          <p>
            Our Services are not intended for children, and we do not knowingly
            collect personal information from children under the age of 13. If
            we become aware that we have collected personal information from a
            child under the age of 13, we will delete it in accordance with
            applicable law.
          </p>
          <p>
            <strong>PRIVACY POLICY UPDATES</strong>
          </p>
          <p>
            If our privacy practices change, we will post an updated Privacy
            Policy. This Privacy Policy will show you the last updated date at
            the top of the privacy policy. Your continued use of the Services
            after any update to this Privacy Policy will constitute your
            acceptance of our changes.
          </p>
          <p>
            <strong>CONTACT INFORMATION</strong>
          </p>
          <p>
            For questions about our Privacy Policy, you can contact us by email
            at privacy@max.live.
          </p>
        </Content>
        {/* <Footer /> */}
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 500;
  overflow-y: scroll;
  button {
    background: none !important;
    border: none;
    padding: 0 !important;
    color: #000;
    text-decoration: underline;
    cursor: pointer;
  }
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled(FlexColumn)`
  margin: 0 auto;
  padding: 40px 24px;
  width: 100%;
  ${Link} {
    color: #1b0076 !important;
    display: inline-block;
    text-decoration: underline;
  }
  ${H4} {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    text-transform: uppercase;
  }
  a {
    word-break: break-all;
  }
  p {
    font-size: 15;
    font-weight: 400;
    line-height: 22px;
    color: ${(props) => props.theme.colors.black80};
    margin-top: 16px;
    strong {
      font-weight: 600;
    }
  }
  ul {
    padding-left: 24px;
    list-style: disc;
    li {
      margin-top: 8px;
      font-size: 15;
      font-weight: 400;
      line-height: 22px;
      color: ${(props) => props.theme.colors.black80};
    }
  }
`;

export default Privacy;
